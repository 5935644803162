import React from "react";
import { Container, Row } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import BlogPost from "./BlogPost";

const BlogHome = ({ data }) => {
  return (
    <section className="blog-one" id="blog">
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Friss hírek, cikkek, inspirációk, fejlesztések"
          titleText={`My Food Protocol Blog`}
        />
        <Row>
          {data.map((item) => (
            <BlogPost key={item.strapi_id} data={item} />
          ))}
        </Row>
        <div className="text-center">
          <a href="/blog" className="thm-btn banner-one__btn">
            <span>További bejegyzések</span>
          </a>
        </div>
      </Container>
    </section>
  );
};

export default BlogHome;
