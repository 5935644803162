import React from "react";
import { Container } from "react-bootstrap";
import Swiper from "react-id-swiper";
import BrandImage from "../assets/images/resources/brand-1-1.png";

import Cta3Shape1 from "../assets/images/shapes/cta-three-bg-1-1.png";
import Cta3Shape2 from "../assets/images/shapes/cta-three-bg-1-2.png";

const Clients = ({ data }) => {
  const params = {
    speed: 1000,
    spaceBetween: 100,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 5,
    rebuildOnUpdate: true,
    // Responsive breakpoints
    breakpoints: {
      0: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
      767: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
      991: {
        spaceBetween: 50,
        slidesPerView: 4,
      },
      1499: {
        slidesPerView: 5,
      },
    },
  };
  return (
    <section className="cta-three">
      <img src={Cta3Shape1} className="cta-three__bg-1" alt="awesome post" />
      <img src={Cta3Shape2} className="cta-three__bg-2" alt="awesome post" />
      <Container>
        <Swiper className="brand-one__carousel" {...params}>
          {data.map((item) => (
            <div key={item.strapi_id} className="item">
              <a href={item.url} target="_blank">
                {item.Logo && (
                  <img
                    src={`${process.env.GATSBY_API_URL}${
                      item.Logo?.formats?.small?.url ?? item.Logo?.url
                    }`}
                    alt={item.Title}
                  />
                )}
                {!item.Logo && <h3>{item.Title}</h3>}
              </a>
            </div>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default Clients;
