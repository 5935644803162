import { useStaticQuery, graphql } from "gatsby";

export const useIndexData = () => {
  const data = useStaticQuery(
    graphql`
      query IndexQuery {
        strapiHero {
          CTA
          Title
          Content {
            data {
              Content
            }
          }
        }
        strapiMission {
          Title
          Content {
            data {
              Content
            }
          }
        }
        strapiTeam {
          Title
          Content {
            data {
              Content
            }
          }
        }
        allStrapiLandingFeature(limit: 20) {
          nodes {
            Title
            Type
            Content {
              data {
                Content
              }
            }
            strapi_id
          }
        }
        allStrapiLandingTeam(sort: { order: ASC, fields: id }) {
          nodes {
            strapi_id
            Name
            Position
            Image {
              url
              width
              height
              formats {
                small {
                  width
                  url
                  height
                }
              }
            }
          }
        }
        allStrapiPost(
          limit: 6
          sort: { order: DESC, fields: Date }
          filter: { publishedAt: { ne: "null" }, Type: { eq: "Blog" } }
        ) {
          nodes {
            Title
            Lead {
              data {
                Lead
              }
            }
            strapi_id
            Image {
              width
              url
              height
              formats {
                medium {
                  url
                  width
                  height
                }
              }
            }
            Date
          }
        }
        strapiProfessional {
          Title
          Content {
            data {
              Content
            }
          }
        }
        strapiCooperate {
          Title
          Lead {
            data {
              Lead
            }
          }
          Content {
            data {
              Content
            }
          }
        }
        allStrapiLandingProfessional(sort: { order: ASC, fields: OrderNo }) {
          nodes {
            Name
            Title
            Image {
              url
              width
              height
              formats {
                small {
                  width
                  url
                  height
                }
              }
            }
            strapi_id
          }
        }
        allStrapiLandingPricing(sort: { fields: OrderNo, order: ASC }) {
          nodes {
            strapi_id
            CTA
            Title
            Lead {
              data {
                Lead
              }
            }
            Content {
              data {
                Content
              }
            }
            url
            isHighlighted
            Price1
            Price2
            Price3
          }
        }
        allStrapiLandingPartner(sort: { fields: OrderNo, order: ASC }) {
          nodes {
            Logo {
              url
              width
              height
              formats {
                small {
                  width
                  url
                  height
                }
              }
            }
            Title
            url
            strapi_id
          }
        }
        strapiPricing {
          Title
          Content {
            data {
              Content
            }
          }
        }
      }
    `
  );
  // console.log("useIndexData", data);
  return data;
};
