import React from "react";
import BlockTitle from "./BlockTitle";
import Swiper from "react-id-swiper";

import TeamShape1 from "../assets/images/shapes/team-1-bg-1-1.png";
import TeamShape2 from "../assets/images/shapes/team-1-bg-1-2.png";
import parse from "html-react-parser";
// require("dotenv").config({
//   path: `.env.${process.env.NODE_ENV}`,
// });

const Professionals = ({ data, team }) => {
  const params = {
    speed: 1000,
    spaceBetween: 100,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 4,
    rebuildOnUpdate: true,
    // Responsive breakpoints
    breakpoints: {
      0: {
        spaceBetween: 30,
        slidesPerView: 1,
      },
      767: {
        spaceBetween: 30,
        slidesPerView: 1,
      },
      991: {
        spaceBetween: 50,
        slidesPerView: 2,
      },
      1499: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <section className="funfact-one" id="team-professional">
      <img
        src={TeamShape1}
        className="team-one__bg-shape-1"
        alt="awesome post"
      />
      <img
        src={TeamShape2}
        className="team-one__bg-shape-2"
        alt="awesome post"
      />
      <div
        className="container prevent-select"
        unselectable="on"
        onMouseDown={() => {
          return false;
        }}
      >
        <BlockTitle
          textAlign="center"
          paraText="A My Food Protocolt támogató szakemberek"
          titleText={data.Title}
        />
        <div className="row">
          <div className="col-12">{parse(data.Content.data.Content)}</div>
        </div>

        <Swiper className="professional__carousel" {...params}>
          {team.map((item) => (
            <div key={item.strapi_id} className="item">
              <div className="team-one__single">
                <div className="team-one__circle"></div>
                <div className="team-one__inner">
                  <h3>{item.Name}</h3>
                  <p>{item.Title}</p>
                  {item.Image && (
                    <div className="team-one__image">
                      <img
                        src={`${process.env.GATSBY_API_URL}${
                          item.Image?.formats?.small?.url ?? item.Image?.url
                        }`}
                        item={data.Name}
                      />
                    </div>
                  )}
                  {/* <div className="team-one__social">
                  <a href="#none">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="#none">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#none">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Professionals;
