import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import parse from "html-react-parser";

const Pricing = ({ data, pricing }) => {
  const [plan, setPlan] = useState(false);
  return (
    <section className="pricing-one" id="pricing-paciens">
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Csomagok"
          titleText={data.Title}
        />
        <div>{parse(data.Content.data.Content)}</div>
        <ul
          className="list-inline text-center switch-toggler-list"
          id="switch-toggle-tab"
        >
          <li className={`month ${plan === false ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(false);
              }}
            >
              Havonta
            </a>
          </li>
          <li>
            <div
              role="button"
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              className={`switch ${plan === true ? "off" : "on"}`}
            >
              <span className="slider round"></span>
            </div>
          </li>
          <li className={`year ${plan === true ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(true);
              }}
            >
              Évente
            </a>
          </li>
        </ul>

        <div id="month">
          <Row>
            {pricing.map((item) => (
              <Col key={item.strapi_id} lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>{item.Title}</p>
                    {!plan && <h3>{item.Price1} Ft</h3>}
                    {plan && <h3>{item.Price2} Ft</h3>}

                    <div className="mt-3 font-weight-bold">
                      {parse(item.Lead.data.Lead)}
                    </div>
                    <div className="mt-3">
                      {parse(item.Content.data.Content)}
                    </div>
                    <a href="#none" className="thm-btn pricing-one__btn mt-3">
                      <span>{item.CTA}</span>
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/*plan === true ? (
          <div id="yearly">
            <Row>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Basic Pack</p>
                    <h3>$59.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Standard Pack</p>
                    <h3>$79.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Ultimate Pack</p>
                    <h3>$99.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div id="month">
            <Row>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Basic Pack</p>
                    <h3>$20.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Standard Pack</p>
                    <h3>$40.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Ultimate Pack</p>
                    <h3>$49.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )*/}
      </Container>
    </section>
  );
};

export default Pricing;
