import React from "react";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import Banner from "../components/Banner";
import BlogHome from "../components/BlogHome";
// import CTAThree from "../components/CTAThree";
// import Contact from "../components/Contact";
import Services from "../components/Services";
import CTAOne from "../components/CTAOne";
// import FunFact from "../components/FunFact";
// import CTATwo from "../components/CTATwo";
import Team from "../components/Team";
import Pricing from "../components/Pricing";
// import VideoOne from "../components/VideoOne";
// import AppScreen from "../components/AppScreen";
// import FAQ from "../components/FAQ";
import Clients from "../components/Clients";
// import Testimonials from "../components/Testimonials";
import { useIndexData } from "../hooks/use-index";
import Professionals from "../components/Professionals";
import Footer from "../components/Footer";

const HomePage = () => {
  const data = useIndexData();
  // console.log("useIndexData", data);

  return (
    <div>
      <Layout pageTitle="MyFoodProtocol">
        <Header
          btnClass="main-nav__btn"
          extraClassName="site-header-one__fixed-top"
        />
        <MobileMenu />
        <Banner data={data.strapiHero} />
        <Services data={data.allStrapiLandingFeature.nodes} />
        <div id="about-us">
          <CTAOne data={data.strapiMission} />
        </div>
        <div id="team">
          <Team data={data.strapiTeam} team={data.allStrapiLandingTeam.nodes} />
        </div>
        <div id="blog">
          <BlogHome data={data.allStrapiPost.nodes} />
        </div>
        <div id="research">
          <Professionals
            data={data.strapiProfessional}
            team={data.allStrapiLandingProfessional.nodes}
          />
        </div>
        <div id="pricing">
          <Pricing
            data={data.strapiPricing}
            pricing={data.allStrapiLandingPricing.nodes}
          />
        </div>
        <Clients data={data.allStrapiLandingPartner.nodes} />
        {/* <FunFact />
        <CTATwo />
        <Testimonials />
        <VideoOne />
        <AppScreen />
        <FAQ />
        <Contact />
        <CTAThree />*/}
        <Footer />
      </Layout>
    </div>
  );
};

export default HomePage;
