import React from "react";
import BlockTitle from "./BlockTitle";

import TeamShape1 from "../assets/images/shapes/team-1-bg-1-1.png";
import TeamShape2 from "../assets/images/shapes/team-1-bg-1-2.png";
import TeamMemeber1 from "../assets/images/team/team-1-1.jpg";
import TeamMemeber2 from "../assets/images/team/team-1-2.jpg";
import TeamMemeber3 from "../assets/images/team/team-1-3.jpg";
import TeamMemeber4 from "../assets/images/team/team-1-4.jpg";
import parse from "html-react-parser";
// require("dotenv").config({
//   path: `.env.${process.env.NODE_ENV}`,
// });

const Team = ({ data, team }) => {
  return (
    <section className="team-one" id="team">
      <img
        src={TeamShape1}
        className="team-one__bg-shape-1"
        alt="awesome post"
      />
      <img
        src={TeamShape2}
        className="team-one__bg-shape-2"
        alt="awesome post"
      />
      <div className="container">
        <BlockTitle
          textAlign="center"
          paraText="Csapatunk"
          titleText={data.Title}
        />
        <div className="row">
          <div className="col-12">{parse(data.Content.data.Content)}</div>
          {team.map((item) => (
            <div key={item.strapi_id} className="col-lg-3 col-md-6 col-sm-12">
              <div className="team-one__single">
                <div className="team-one__circle"></div>
                <div className="team-one__inner">
                  <h3>{item.Name}</h3>
                  <p>{item.Position ?? item.Title}</p>
                  {item.Image && (
                    <div className="team-one__image">
                      <img
                        src={`${process.env.GATSBY_API_URL}${
                          item.Image?.formats?.small?.url ?? item.Image?.url
                        }`}
                        item={data.Name}
                      />
                    </div>
                  )}
                  {/* <div className="team-one__social">
                  <a href="#none">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="#none">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#none">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
