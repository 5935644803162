import React from "react";
import BlockTitle from "./BlockTitle";
import parse from "html-react-parser";

const Services = ({ data }) => {
  const paciens = data.filter((item) => item.Type === "paciens");
  const terapeuta = data.filter((item) => item.Type === "terapeuta");

  return (
    <section className="service-one" id="features">
      <div className="container">
        <h3>Kinek szól</h3>
        <BlockTitle
          textAlign="center"
          paraText="Funkciólista"
          titleText={`Az MFP a következő szolgáltatásokat nyújtja Páciensek számára:`}
        />
        <div className="row" id="features-paciens">
          {paciens.map((item) => (
            <div key={item.strapi_id} className="col-lg-6 col-md-12">
              <div className="service-one__single">
                <div className="service-one__inner">
                  {/* <div className="service-one__circle"></div> */}
                  <div className="d-flex justify-content-center align-items-center mx-auto max-width-80">
                    <div className="service-one__icon mx-0 min-width-129px">
                      {item.Icon && <i className={item.Icon}></i>}
                      {!item.Icon && (
                        <i className="apton-icon-computer-graphic"></i>
                      )}
                    </div>
                    <h3 className="ms-3 flex-grow-0">{item.Title}</h3>
                  </div>
                  <div>{parse(item.Content.data.Content)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <BlockTitle
          textAlign="center"
          paraText="Funkciólista"
          titleText={`Az MFP a következő szolgáltatásokat nyújtja Terapeuták számára:`}
        />
        <div className="row" id="features-terapeuta">
          {terapeuta.map((item) => (
            <div key={item.strapi_id} className="col-lg-6 col-md-12">
              <div className="service-one__single">
                <div className="service-one__inner">
                  {/* <div className="service-one__circle"></div> */}
                  <div className="d-flex justify-content-center align-items-center mx-auto max-width-80">
                    <div className="service-one__icon mx-0">
                      {item.Icon && <i className={item.Icon}></i>}
                      {!item.Icon && (
                        <i className="apton-icon-computer-graphic"></i>
                      )}
                    </div>
                    <h3 className="ms-3">{item.Title}</h3>
                  </div>
                  <div>{parse(item.Content.data.Content)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
