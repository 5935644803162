import React from "react";
import BlockTitle from "./BlockTitle";
import parse from "html-react-parser";

import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";

const CTAOne = ({ data }) => {
  return (
    <section className="cta-one" id="mission">
      <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
      <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
      <div className="container">
        <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
          <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-9">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="Rólunk"
                titleText={data.Title}
              />
              <div className="cta-one__text">
                {parse(data.Content.data.Content)}
              </div>
              {/* <a href="#none" className="thm-btn cta-one__btn">
                <span>Discover More</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;
